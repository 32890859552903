var render = function () {
  var _vm$customerDataInfo, _vm$customerDataInfo2, _vm$customerDataInfo3, _vm$customerDataInfo4, _vm$customerDataInfo5, _vm$customerDataInfo6, _vm$customerDataInfo7, _vm$customerDataInfo8, _vm$customerDataInfo$, _vm$customerDataInfo$2, _vm$customerDataInfo$3, _vm$customerDataInfo9, _vm$customerDataInfo10, _vm$customerDataInfo11, _vm$customerDataInfo12, _vm$customerDataInfo13, _vm$customerDataInfo14, _vm$customerDataInfo15, _vm$customerDataInfo16, _vm$customerDataInfo17, _vm$customerDataInfo18, _vm$customerDataInfo19, _vm$customerDataInfo20, _vm$customerDataInfo21, _vm$customerDataInfo22, _vm$customerDataInfo23, _vm$customerDataInfo24, _vm$customerDataInfo25, _vm$customerDataInfo26, _vm$customerDataInfo27, _vm$customerDataInfo28, _vm$customerDataInfo29, _vm$customerDataInfo30, _vm$customerDataInfo31, _vm$customerDataInfo32, _vm$customerDataInfo33, _vm$customerDataInfo34, _vm$customerDataInfo35, _vm$customerDataInfo36, _vm$customerDataInfo37, _vm$customerDataInfo38, _vm$genderOptionsGend;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center ",
    staticStyle: {
      "gap": "3%"
    }
  }, [_c('b-card', {
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between ",
    attrs: {
      "label": "".concat(_vm.$t('customer.lastName'), " ").concat(_vm.isEditActive, " "),
      "label-for": "lastName"
    }
  }, [_c('validation-provider', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "name": "Last Name",
      "rules": "required",
      "mode": "eager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "id": "lastName",
            "disabled": _vm.readonlys,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('customer.lastName')
          },
          model: {
            value: _vm.customerDataInfo.lastName,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "lastName", $$v);
            },
            expression: "customerDataInfo.lastName"
          }
        })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.lastName || '-'))])]), _c('b-form-invalid-feedback', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between ",
    attrs: {
      "label": "".concat(_vm.$t('customer.firstName'), " ").concat(_vm.isEditActive),
      "label-for": "firstName"
    }
  }, [_c('validation-provider', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "name": "First Name",
      "rules": "required",
      "mode": "eager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "id": "firstName",
            "disabled": _vm.readonlys,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('customer.firstName')
          },
          model: {
            value: _vm.customerDataInfo.firstName,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "firstName", $$v);
            },
            expression: "customerDataInfo.firstName"
          }
        })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.firstName || '-'))])]), _c('b-form-invalid-feedback', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1), _c('b-col'), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.createdAt'), " "),
      "label-for": "createdAt"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('flat-pickr', {
    staticClass: "form-control mb-0 ",
    staticStyle: {
      "background": "#e0e0e0  !important"
    },
    attrs: {
      "id": "createdAt",
      "disabled": _vm.mode === 'edit',
      "placeholder": _vm.$t('customer.createdAt'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
        altInput: true,
        disableMobile: true,
        minDate: 'today'
      }
    },
    model: {
      value: _vm.customerDataInfo.createdAt,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "createdAt", $$v);
      },
      expression: "customerDataInfo.createdAt"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate(_vm.customerDataInfo.createdAt) || '-'))])])])], 1)], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between "
  }, [_c('h6', {
    staticClass: "text-body m-0"
  }, [_vm._v(" Mạng xã hội ")]), _vm.readonlys === false ? _c('div', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary.window",
      modifiers: {
        "hover": true,
        "v-primary": true,
        "window": true
      }
    }],
    staticClass: "btn-icon py-25 px-50 d-flex-center",
    attrs: {
      "title": "Chỉnh sửa",
      "size": "sm",
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.editSocialNetwork(_vm.customerDataInfo);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@icons/edit.svg")
    }
  }), !_vm.isMobileView ? _c('span', {
    staticClass: "font-medium-2 ml-25 ",
    staticStyle: {
      "margin-top": "1px",
      "display": "flex",
      "justify-content": "center"
    }
  }) : _vm._e()])], 1) : _c('div', {
    staticClass: "d-none"
  })]), _vm.customerDataInfo.socialAccounts ? _c('div', _vm._l(_vm.customerDataInfo.socialAccounts, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "mb-50 d-flex align-items-center ml-1"
    }, [_c('span', {
      staticClass: "mr-50 font-weight-bolder",
      staticStyle: {
        "min-width": "120px"
      }
    }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('b-link', {
      staticClass: "url-ellipsis",
      attrs: {
        "href": item.url,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.url) + " ")])], 1);
  }), 0) : _c('div', [_vm._v(" Chưa thêm mạng xã hội ")]), _vm.customerDataInfo ? _c('ModalEditContactSocialNetwork', {
    attrs: {
      "social-accounts": _vm.customerDataInfo.socialAccounts
    },
    on: {
      "updateDetailSocialAccounts": _vm.handleDetailSocialAccountsUpdate
    }
  }) : _vm._e()], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-1",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.phoneNumber')),
      "label-for": "phone-number"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    attrs: {
      "id": "phone-number",
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.phoneNumber')
    },
    model: {
      value: _vm.customerDataInfo.phoneNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "phoneNumber", $$v);
      },
      expression: "customerDataInfo.phoneNumber"
    }
  })], 1) : _c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.customerDataInfo.phoneNumber || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.emailAddress'), " "),
      "label-for": "email"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
    attrs: {
      "id": "email",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.emailAddress')
    },
    model: {
      value: _vm.customerDataInfo.emailAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "emailAddress", $$v);
      },
      expression: "customerDataInfo.emailAddress"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.emailAddress || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.gender'), " ").concat(_vm.isEditActive),
      "label-for": "gender"
    }
  }, [!_vm.readonlys ? _c('div', [_c('v-select', {
    staticClass: "fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "background": "#ffff",
      "border-radius": "10px"
    },
    attrs: {
      "label": "value",
      "options": _vm.genderOptionsGender,
      "reduce": function reduce(option) {
        return option.value;
      },
      "placeholder": _vm.$t('customer.gender'),
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_c('span', [_vm._v(_vm._s(_vm.$t("customer.genderOptionsGenders.".concat(value))))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_c('span', [_vm._v(_vm._s(_vm.$t("customer.genderOptionsGenders.".concat(value))))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 443312482),
    model: {
      value: _vm.customerDataInfo.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "gender", $$v);
      },
      expression: "customerDataInfo.gender"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("customer.genderOptionsGenders.".concat(_vm.customerDataInfo.gender)) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Birthday'), " "),
      "label-for": "birthDay"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Birthday",
      "rules": "ageCategory:".concat(_vm.customerDataInfo.gender)
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [!_vm.readonlys ? _c('div', {
          staticStyle: {
            "max-width": "190px !important"
          }
        }, [_c('flat-pickr', {
          staticClass: "d-none",
          model: {
            value: _vm.customerDataInfo.birthDay,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "birthDay", $$v);
            },
            expression: "customerDataInfo.birthDay"
          }
        }), _c('flat-pickr', {
          staticClass: "form-control mb-0 bg-white",
          attrs: {
            "id": "birthDay",
            "disabled": _vm.readonlys,
            "placeholder": _vm.$t('customer.Birthday'),
            "state": errors.length > 0 ? false : null,
            "config": {
              allowInput: true,
              locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
              dateFormat: 'Y-m-d',
              altFormat: 'd-m-Y',
              altInput: true,
              disableMobile: true,
              maxDate: 'today'
            }
          },
          model: {
            value: _vm.customerDataInfo.birthDay,
            callback: function callback($$v) {
              _vm.$set(_vm.customerDataInfo, "birthDay", $$v);
            },
            expression: "customerDataInfo.birthDay"
          }
        })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate(_vm.customerDataInfo.birthDay) || '-'))])]), _c('b-form-invalid-feedback', {
          staticStyle: {
            "max-width": "190px"
          },
          attrs: {
            "state": errors.length > 0 ? false : null
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center ",
    staticStyle: {
      "gap": "3%"
    }
  }, [_c('b-card', {
    staticClass: "mb-1",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.identifyNumber'), " "),
      "label-for": "identify-number"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    attrs: {
      "id": "identify-number",
      "disabled": _vm.readonlys,
      "maxlength": "15",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.identifyNumber')
    },
    model: {
      value: _vm.customerDataInfo.identifyNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "identifyNumber", $$v);
      },
      expression: "customerDataInfo.identifyNumber"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.identifyNumber || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Dateofissue')),
      "label-for": "Dateofissue"
    }
  }, [_vm.readonlys === false ? _c('div', [(_vm$customerDataInfo = _vm.customerDataInfo) !== null && _vm$customerDataInfo !== void 0 && _vm$customerDataInfo.identifyConfig ? _c('div', [_c('flat-pickr', {
    staticClass: "form-control mb-0 bg-white",
    attrs: {
      "id": "Dateofissue",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.Dateofissue'),
      "config": {
        allowInput: true,
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        disableMobile: true
      }
    },
    model: {
      value: _vm.customerDataInfo.identifyConfig.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.identifyConfig, "startDate", $$v);
      },
      expression: "customerDataInfo.identifyConfig.startDate"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate((_vm$customerDataInfo2 = _vm.customerDataInfo) === null || _vm$customerDataInfo2 === void 0 ? void 0 : (_vm$customerDataInfo3 = _vm$customerDataInfo2.identifyConfig) === null || _vm$customerDataInfo3 === void 0 ? void 0 : _vm$customerDataInfo3.startDate) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.expirationDate')),
      "label-for": "expirationDate"
    }
  }, [_vm.readonlys === false ? _c('div', [(_vm$customerDataInfo4 = _vm.customerDataInfo) !== null && _vm$customerDataInfo4 !== void 0 && _vm$customerDataInfo4.identifyConfig ? _c('div', [_c('flat-pickr', {
    staticClass: "form-control mb-0 bg-white",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "expirationDate",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.expirationDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.customerDataInfo.identifyConfig.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.identifyConfig, "endDate", $$v);
      },
      expression: "customerDataInfo.identifyConfig.endDate"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate((_vm$customerDataInfo5 = _vm.customerDataInfo) === null || _vm$customerDataInfo5 === void 0 ? void 0 : (_vm$customerDataInfo6 = _vm$customerDataInfo5.identifyConfig) === null || _vm$customerDataInfo6 === void 0 ? void 0 : _vm$customerDataInfo6.endDate) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Placeofissue'), " "),
      "label-for": "identify-number"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.identifyConfig ? _c('div', [_c('b-form-input', {
    attrs: {
      "id": "identify-number",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.Placeofissue')
    },
    model: {
      value: _vm.customerDataInfo.identifyConfig.placeOfIssue,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.identifyConfig, "placeOfIssue", $$v);
      },
      expression: "customerDataInfo.identifyConfig.placeOfIssue"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(((_vm$customerDataInfo7 = _vm.customerDataInfo) === null || _vm$customerDataInfo7 === void 0 ? void 0 : (_vm$customerDataInfo8 = _vm$customerDataInfo7.identifyConfig) === null || _vm$customerDataInfo8 === void 0 ? void 0 : _vm$customerDataInfo8.placeOfIssue) || '-'))])])])], 1)], 1), _vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.focus.top",
      value: "Qu\xE9t QR Code CCCD/Passport",
      expression: "`Quét QR Code CCCD/Passport`",
      modifiers: {
        "hover": true,
        "focus": true,
        "top": true
      }
    }],
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickQrScanHandle("qr-code-scan-passport-identify");
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "qrCode",
      "size": "18",
      "color": "black"
    }
  })], 1), _c('IAmQrCodeScan', {
    attrs: {
      "idProp": "qr-code-scan-passport-identify"
    },
    on: {
      "apply": function apply(value) {
        return _vm.customerDataInfo.identifyNumber = value;
      }
    }
  }), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.focus.top",
      value: "T\u1EA3i \u1EA2nh \u0111\u1EC3 qu\xE9t QR Code CCCD/Passport",
      expression: "`Tải Ảnh để quét QR Code CCCD/Passport`",
      modifiers: {
        "hover": true,
        "focus": true,
        "top": true
      }
    }],
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickQrScanHandle("qr-code-scan-by-upload-passport-identify");
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "cloudUploadOutline",
      "size": "18",
      "color": "black"
    }
  })], 1), _c('IAmQrCodeScanByUpload', {
    attrs: {
      "idProp": "qr-code-scan-by-upload-passport-identify"
    },
    on: {
      "apply": function apply(value) {
        return _vm.customerDataInfo.identifyNumber = value;
      }
    }
  })], 1) : _c('div', {
    staticClass: "d-none"
  })], 1), _c('b-card', {
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.city')),
      "label-for": "city"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-group', {
    staticClass: "mb-0 form-group-custom "
  }), _c('v-select', {
    staticClass: "bg-white fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "border-radius": "10px"
    },
    attrs: {
      "id": "city",
      "reduce": function reduce(val) {
        return val.id;
      },
      "options": _vm.optionsIdCity,
      "label": "name",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.city')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref4) {
        var name = _ref4.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref5) {
        var name = _ref5.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 116065154),
    model: {
      value: _vm.customerDataInfo.addressCity,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "addressCity", $$v);
      },
      expression: "customerDataInfo.addressCity"
    }
  })], 1) : _c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$customerDataInfo$ = _vm.customerDataInfo.addressCity) === null || _vm$customerDataInfo$ === void 0 ? void 0 : _vm$customerDataInfo$.name) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.district')),
      "label-for": "district"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('v-select', {
    staticClass: "fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "width": "100%",
      "background": "#fff",
      "border-radius": "10px"
    },
    attrs: {
      "id": "district",
      "options": _vm.optionsIdDitric,
      "reduce": function reduce(val) {
        return val.id;
      },
      "disabled": _vm.readonlys,
      "label": "name",
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.district')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref6) {
        var name = _ref6.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref7) {
        var name = _ref7.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 1577615586),
    model: {
      value: _vm.customerDataInfo.addressDistrict,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "addressDistrict", $$v);
      },
      expression: "customerDataInfo.addressDistrict"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(((_vm$customerDataInfo$2 = _vm.customerDataInfo.addressDistrict) === null || _vm$customerDataInfo$2 === void 0 ? void 0 : _vm$customerDataInfo$2.name) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.ward')),
      "label-for": "social-accounts"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('v-select', {
    staticClass: "fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "background": "#ffff",
      "border-radius": "10px"
    },
    attrs: {
      "id": "social-accounts",
      "disabled": _vm.readonlys,
      "reduce": function reduce(val) {
        return val.id;
      },
      "label": "name",
      "options": _vm.optionsIdWard,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.ward')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref8) {
        var name = _ref8.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref9) {
        var name = _ref9.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 116065154),
    model: {
      value: _vm.customerDataInfo.addressWard,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "addressWard", $$v);
      },
      expression: "customerDataInfo.addressWard"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(((_vm$customerDataInfo$3 = _vm.customerDataInfo.addressWard) === null || _vm$customerDataInfo$3 === void 0 ? void 0 : _vm$customerDataInfo$3.name) || '-'))])])])], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.street')),
      "label-for": "street"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-input', {
    staticStyle: {
      "background": "#ffff",
      "border-radius": "5px"
    },
    attrs: {
      "id": "street",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.street')
    },
    model: {
      value: _vm.customerDataInfo.addressStreet,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "addressStreet", $$v);
      },
      expression: "customerDataInfo.addressStreet"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.addressStreet || '-'))])])])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center ",
    staticStyle: {
      "gap": "3%"
    }
  }, [_c('b-card', {
    staticClass: "mb-1",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.passPort'), " "),
      "label-for": "passportNumber"
    }
  }, [_vm.readonlys === false ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-form-input', {
    attrs: {
      "id": "passportNumber",
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.passPort')
    },
    model: {
      value: _vm.customerDataInfo.passportNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "passportNumber", $$v);
      },
      expression: "customerDataInfo.passportNumber"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.passportNumber || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Dateofissue')),
      "label-for": "Dateofissue"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.passportConfig ? _c('div', [_c('flat-pickr', {
    staticClass: "form-control mb-0 bg-white",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "Dateofissue",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.Dateofissue'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        disableMobile: true,
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null
      }
    },
    model: {
      value: _vm.customerDataInfo.passportConfig.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.passportConfig, "startDate", $$v);
      },
      expression: "customerDataInfo.passportConfig.startDate"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate((_vm$customerDataInfo9 = _vm.customerDataInfo) === null || _vm$customerDataInfo9 === void 0 ? void 0 : (_vm$customerDataInfo10 = _vm$customerDataInfo9.passportConfig) === null || _vm$customerDataInfo10 === void 0 ? void 0 : _vm$customerDataInfo10.startDate) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.expirationDate')),
      "label-for": "expirationDate"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.passportConfig ? _c('div', [_c('flat-pickr', {
    staticClass: "form-control mb-0 bg-white",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "expirationDate",
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.expirationDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        disableMobile: true,
        locale: _vm.locale === 'vi' ? _vm.Vietnamese : null
      }
    },
    model: {
      value: _vm.customerDataInfo.passportConfig.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.passportConfig, "endDate", $$v);
      },
      expression: "customerDataInfo.passportConfig.endDate"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.getDate((_vm$customerDataInfo11 = _vm.customerDataInfo) === null || _vm$customerDataInfo11 === void 0 ? void 0 : (_vm$customerDataInfo12 = _vm$customerDataInfo11.passportConfig) === null || _vm$customerDataInfo12 === void 0 ? void 0 : _vm$customerDataInfo12.endDate) || '-'))])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Placeofissue'), " "),
      "label-for": "identify-number"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.passportConfig ? _c('div', [_c('IAmCountrySelect', {
    staticClass: "bg-white fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "border-radius": "10px"
    },
    attrs: {
      "id": 'identify-number',
      "disabled": _vm.readonlys,
      "maxlength": "30",
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.Placeofissue')
    },
    model: {
      value: _vm.customerDataInfo.passportConfig.placeOfIssue,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.passportConfig, "placeOfIssue", $$v);
      },
      expression: "customerDataInfo.passportConfig.placeOfIssue"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [(_vm$customerDataInfo13 = _vm.customerDataInfo) !== null && _vm$customerDataInfo13 !== void 0 && (_vm$customerDataInfo14 = _vm$customerDataInfo13.passportConfig) !== null && _vm$customerDataInfo14 !== void 0 && _vm$customerDataInfo14.placeOfIssue ? _c('span', [_vm._v(" " + _vm._s((_vm$customerDataInfo15 = _vm.customerDataInfo) !== null && _vm$customerDataInfo15 !== void 0 && (_vm$customerDataInfo16 = _vm$customerDataInfo15.passportConfig) !== null && _vm$customerDataInfo16 !== void 0 && (_vm$customerDataInfo17 = _vm$customerDataInfo16.placeOfIssue) !== null && _vm$customerDataInfo17 !== void 0 && _vm$customerDataInfo17.countryName ? "".concat((_vm$customerDataInfo18 = _vm.customerDataInfo) === null || _vm$customerDataInfo18 === void 0 ? void 0 : (_vm$customerDataInfo19 = _vm$customerDataInfo18.passportConfig) === null || _vm$customerDataInfo19 === void 0 ? void 0 : (_vm$customerDataInfo20 = _vm$customerDataInfo19.placeOfIssue) === null || _vm$customerDataInfo20 === void 0 ? void 0 : _vm$customerDataInfo20.countryName, "(").concat((_vm$customerDataInfo21 = _vm.customerDataInfo) === null || _vm$customerDataInfo21 === void 0 ? void 0 : (_vm$customerDataInfo22 = _vm$customerDataInfo21.passportConfig) === null || _vm$customerDataInfo22 === void 0 ? void 0 : (_vm$customerDataInfo23 = _vm$customerDataInfo22.placeOfIssue) === null || _vm$customerDataInfo23 === void 0 ? void 0 : _vm$customerDataInfo23.longCode, ")") : (_vm$customerDataInfo24 = _vm.customerDataInfo) === null || _vm$customerDataInfo24 === void 0 ? void 0 : (_vm$customerDataInfo25 = _vm$customerDataInfo24.passportConfig) === null || _vm$customerDataInfo25 === void 0 ? void 0 : _vm$customerDataInfo25.placeOfIssue) + " ")]) : _c('span', [_vm._v("-")])])])], 1)], 1), _c('b-col', [_c('b-row', [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.nationality'), " "),
      "label-for": "nationality"
    }
  }, [_vm.readonlys === false ? _c('div', [_vm.customerDataInfo.passportConfig ? _c('div', [_c('IAmCountrySelect', {
    staticClass: "bg-white fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "border-radius": "10px"
    },
    attrs: {
      "id": 'city',
      "disabled": _vm.readonlys,
      "placeholder": _vm.$t('customer.nationality')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref10) {
        var name = _ref10.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref11) {
        var name = _ref11.name;
        return [_c('span', [_vm._v(_vm._s(name))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 116065154),
    model: {
      value: _vm.customerDataInfo.passportConfig.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo.passportConfig, "nationality", $$v);
      },
      expression: "customerDataInfo.passportConfig.nationality"
    }
  })], 1) : _c('div', [_c('span', [_vm._v("-")])])]) : _c('div', [(_vm$customerDataInfo26 = _vm.customerDataInfo) !== null && _vm$customerDataInfo26 !== void 0 && (_vm$customerDataInfo27 = _vm$customerDataInfo26.passportConfig) !== null && _vm$customerDataInfo27 !== void 0 && _vm$customerDataInfo27.nationality ? _c('span', [_vm._v(" " + _vm._s((_vm$customerDataInfo28 = _vm.customerDataInfo) !== null && _vm$customerDataInfo28 !== void 0 && (_vm$customerDataInfo29 = _vm$customerDataInfo28.passportConfig) !== null && _vm$customerDataInfo29 !== void 0 && (_vm$customerDataInfo30 = _vm$customerDataInfo29.nationality) !== null && _vm$customerDataInfo30 !== void 0 && _vm$customerDataInfo30.countryName ? "".concat((_vm$customerDataInfo31 = _vm.customerDataInfo) === null || _vm$customerDataInfo31 === void 0 ? void 0 : (_vm$customerDataInfo32 = _vm$customerDataInfo31.passportConfig) === null || _vm$customerDataInfo32 === void 0 ? void 0 : (_vm$customerDataInfo33 = _vm$customerDataInfo32.nationality) === null || _vm$customerDataInfo33 === void 0 ? void 0 : _vm$customerDataInfo33.countryName, "(").concat((_vm$customerDataInfo34 = _vm.customerDataInfo) === null || _vm$customerDataInfo34 === void 0 ? void 0 : (_vm$customerDataInfo35 = _vm$customerDataInfo34.passportConfig) === null || _vm$customerDataInfo35 === void 0 ? void 0 : (_vm$customerDataInfo36 = _vm$customerDataInfo35.nationality) === null || _vm$customerDataInfo36 === void 0 ? void 0 : _vm$customerDataInfo36.longCode, ")") : (_vm$customerDataInfo37 = _vm.customerDataInfo) === null || _vm$customerDataInfo37 === void 0 ? void 0 : (_vm$customerDataInfo38 = _vm$customerDataInfo37.passportConfig) === null || _vm$customerDataInfo38 === void 0 ? void 0 : _vm$customerDataInfo38.nationality) + " ")]) : _c('span', [_vm._v("-")])])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "p-0"
  }, [_c('b-form-group', {
    staticClass: "font-weight-bold d-flex flex-1 justify-content-between align-items-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "".concat(_vm.$t('customer.Gender')),
      "label-for": "gender"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('v-select', {
    staticClass: "fixed-width-select",
    staticStyle: {
      "max-width": "190px",
      "background": "#ffff",
      "border-radius": "10px"
    },
    attrs: {
      "label": "label",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "placeholder": _vm.$t('customer.Gender'),
      "options": _vm.genderOptionsGender,
      "reduce": function reduce(val) {
        return val.value;
      },
      "clearable": false,
      "disabled": true,
      "input-id": "gender-type"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref12) {
        var label = _ref12.label;
        return [_vm._v(" " + _vm._s(_vm.$t("customer.genderOptionsGender.".concat(label))) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref13) {
        var label = _ref13.label;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("customer.genderOptionsGender.".concat(label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 2822569145),
    model: {
      value: _vm.customerDataInfo.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "gender", $$v);
      },
      expression: "customerDataInfo.gender"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t("customer.genderOptionsGender.".concat(((_vm$genderOptionsGend = _vm.genderOptionsGender.find(function (option) {
    return option.value === _vm.customerDataInfo.gender;
  })) === null || _vm$genderOptionsGend === void 0 ? void 0 : _vm$genderOptionsGend.label) || '-'))) + " ")])])])], 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-1",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "360px"
    }
  }, [_vm.customerDataInfo.memberCards ? _c('div', [_c('b-col', [_c('b-row', _vm._l(_vm.customerDataInfo.memberCards, function (item, index) {
    var _vm$customerDataInfo39, _vm$customerDataInfo40;
    return _c('div', {
      key: index,
      staticStyle: {
        "width": "100%"
      }
    }, [_vm.readonlys === false ? _c('div', {
      staticClass: "d-flex justify-content-end font-weight-bolder mb-1 align-items-center",
      staticStyle: {
        "width": "100%"
      }
    }, [_c('v-select', {
      staticClass: "membership-airline-select",
      attrs: {
        "label": "airline",
        "placeholder": _vm.$t('customer.memberCards.airline'),
        "options": _vm.optionsSource,
        "clearable": false,
        "disabled": _vm.readonlys,
        "input-id": "type"
      },
      scopedSlots: _vm._u([{
        key: "option",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "fw-700"
          }, [_vm._v(" " + _vm._s(data.airline) + " "), _c('span', {
            staticClass: "text-warning ml-50"
          }, [_vm._v("(" + _vm._s(data.supplierCode) + ")")])])];
        }
      }, {
        key: "no-options",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.customerDataInfo.memberCards[index],
        callback: function callback($$v) {
          _vm.$set(_vm.customerDataInfo.memberCards, index, $$v);
        },
        expression: "customerDataInfo.memberCards[index]"
      }
    })], 1) : _c('div', [(_vm$customerDataInfo39 = _vm.customerDataInfo) !== null && _vm$customerDataInfo39 !== void 0 && _vm$customerDataInfo39.memberCards[index] ? _c('span', [_vm._v(_vm._s("".concat((_vm$customerDataInfo40 = _vm.customerDataInfo) === null || _vm$customerDataInfo40 === void 0 ? void 0 : _vm$customerDataInfo40.memberCards[index].airline) || '-'))]) : _c('span', [_vm._v("-")])]), _vm.readonlys === false ? _c('div', {
      staticClass: "d-flex justify-content-end font-weight-bold align-items-center mb-1",
      staticStyle: {
        "width": "100"
      }
    }, [_c('b-form-input', {
      staticStyle: {
        "max-width": "100%",
        "border": "none!important",
        "border-radius": "50px !important"
      },
      attrs: {
        "id": "membership-card",
        "disabled": _vm.readonlys,
        "maxlength": "30",
        "lazy-formatter": "",
        "formatter": _vm.trimInput,
        "placeholder": _vm.$t('customer.memberCards.cardNumber')
      },
      model: {
        value: item.cardNumber,
        callback: function callback($$v) {
          _vm.$set(item, "cardNumber", $$v);
        },
        expression: "item.cardNumber "
      }
    })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(item.cardNumber || '-'))])]), _vm.readonlys === false ? _c('div', {
      staticClass: "d-flex justify-content-end gap-1"
    }, [_c('b-button', {
      staticStyle: {
        "background": "none !important",
        "color": "#EE0000 !important",
        "border": "none !important",
        "box-shadow": "none !important"
      },
      attrs: {
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.removeMemberCard(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XCircleIcon"
      }
    }), _vm._v(" " + _vm._s(_vm.$t('customer.deleteMemberCard')) + " ")], 1), _c('b-button', {
      staticStyle: {
        "background": "none !important",
        "color": "rgba(239, 173, 2, 1) !important",
        "border": "none !important",
        "box-shadow": "none !important"
      },
      attrs: {
        "size": "sm"
      },
      on: {
        "click": _vm.addMemberCard
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusCircleIcon"
      }
    }), _vm._v(" " + _vm._s(_vm.$t('customer.addNewMemberCard')) + " ")], 1)], 1) : _c('div', {
      staticClass: "d-none"
    })]);
  }), 0)], 1)], 1) : _c('div', {
    staticClass: "mb-2"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('customer.noMemberShipCard')) + "!")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center "
  }, [_c('b-card', {
    staticClass: "mx-2",
    staticStyle: {
      "background": "#f3f3f3",
      "width": "100%"
    }
  }, [_vm.readonlys === false ? _c('div', [_c('b-form-textarea', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "note",
      "lazy-formatter": "",
      "disabled": _vm.readonlys,
      "formatter": _vm.trimInput,
      "placeholder": _vm.$t('customer.note'),
      "rows": "4",
      "no-auto-shrink": ""
    },
    model: {
      value: _vm.customerDataInfo.note,
      callback: function callback($$v) {
        _vm.$set(_vm.customerDataInfo, "note", $$v);
      },
      expression: "customerDataInfo.note"
    }
  })], 1) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.customerDataInfo.note || '-'))])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }